
import { PropType, defineComponent } from 'vue';
import { ProductTypes } from '@/data/productTypes';
import PopupSystem from '@/shared/scripts/PopupSystem';

export default defineComponent({
  name: 'Product card',
  props: {
    product: {
      type: Object as PropType<ProductTypes>,
      required: true
    },
    currentPriceToInfo: {
      type: Boolean,
      required: true
    },
    otherProductsStyle: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    offer() {
      PopupSystem.invokePopup('offerPopup', {});
    }
  }
})

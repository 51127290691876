
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import PopupSystem from '@/shared/scripts/PopupSystem';
import OfferPopup from '@/shared/components/popups/OfferPopup.vue';

export default defineComponent({
  name: 'Popups Host',
  components: {
    OfferPopup,
  },
  computed: {
    ...mapState([ 'activePopupType', 'activePopupProps' ]),
  },
  methods: {
    onPopupHostClick() {
			PopupSystem.dismissPopup();
		},
    onClose() {
			PopupSystem.dismissPopup();
		},
  },
  created() {
    PopupSystem.init(this);

    PopupSystem.registerPopupType('offerPopup', 'OfferPopup');
  },
})


import { defineComponent } from 'vue';
import TopBar from '@/components/TopBar.vue';
import MainInfo from '@/components/MainInfo.vue';
import Links from './Links.vue';

export default defineComponent({
  name: 'Header',
  components: {
    TopBar,
    MainInfo,
    Links,
  }
})

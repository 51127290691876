<template>
  <div class="links-wrapper">
    <div class="instagram-wrapper">
      <a href="https://instagram.com/drova_bel_?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" class="instagram"></a>
      <a href="https://instagram.com/drova_bel_?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" class="instagram-label">drova_bel_</a>
    </div>
    <div class="break-line"></div>
    <div class="tik-tok-wrapper">
      <a href="https://www.tiktok.com/@drovaminsk?_t=8dxYSh4DJ9K" target="_blank" class="tik-tok"></a>
      <a href="https://www.tiktok.com/@drovabel.by?_t=8oU9Kz7BbIU&_r=1" target="_blank" class="tik-tok-label">drovabel.by</a>
    </div>
  </div>
</template>

<style lang="scss">
.links-wrapper
{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 288px;
}

.instagram-wrapper
{
  display: flex;
  align-items: center;
  gap: 10px;
}

.instagram
{
  width: 33px;
  height: 33px;
  background-image: url('@/assets/instagram.png');
}

.instagram-label
{
  color: $primary-color;
  font-size: 22px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.tik-tok-wrapper
{
  display: flex;
  align-items: center;
  gap: 10px;
}

.tik-tok
{
  width: 26px;
  height: 29px;
  background-image: url('@/assets/tik-tok.png');
}

.tik-tok-label
{
  color: $primary-color;
  font-size: 22px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

@media screen and (max-width: 1300px) 
{
  .links-wrapper
  {
    display: none;
  }  
}
</style>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-view-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Catalog = _resolveComponent("Catalog")!
  const _component_AboutPurchase = _resolveComponent("AboutPurchase")!
  const _component_Other = _resolveComponent("Other")!
  const _component_AboutCompany = _resolveComponent("AboutCompany")!
  const _component_FirewoodTypes = _resolveComponent("FirewoodTypes")!
  const _component_Contacts = _resolveComponent("Contacts")!
  const _component_PopupsHost = _resolveComponent("PopupsHost")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Head),
    _createVNode(_component_Catalog),
    _createVNode(_component_AboutPurchase),
    _createVNode(_component_Other),
    _createVNode(_component_AboutCompany),
    _createVNode(_component_FirewoodTypes),
    _createElementVNode("footer", null, [
      _createVNode(_component_Contacts)
    ]),
    _createVNode(_component_PopupsHost)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-wrapper" }
const _hoisted_2 = { class: "central-elements-align" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_MainInfo = _resolveComponent("MainInfo")!
  const _component_Links = _resolveComponent("Links")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, [
        _createVNode(_component_TopBar)
      ]),
      _createVNode(_component_MainInfo),
      _createVNode(_component_Links)
    ])
  ]))
}
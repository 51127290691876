
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Burger',
  data() {
    return {
      toggleDropZone: false,
    }
  }
})

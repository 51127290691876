<template>
  <div class="other-wrapper">
    <div class="other">
      <div class="other-card-wrapper">
        <h3 class="other-card-title">У Вас остались вопросы или необходима консультация ?</h3>
        <div class="other-card-notice">Позвоните или напишите нам и мы ответим на все ваши вопросы </div>
        <div class="other-card-contacts">
          <a href="tel:+375 29 551 1881" class="other-card-contact">+375 29 551-1881</a>
          <a href="tel:+375 29 557 5558" class="other-card-contact">+375 29 557-5558</a>
        </div>
        <div class="other-card-messengers">
          <a href="viber://chat/?number=%2B375295511881" class="viber34x36"></a>
          <a href="https://t.me/+375295511881" class="telegram36x36"></a>
          <a href="https://wa.me/375295511881" class="whatsapp40x40"></a>
        </div>
      </div>
      <div class="other-image"></div>
    </div>
  </div>
</template>

<style lang="scss">
.other-wrapper
{
  display: flex;
  flex-direction: column;
  align-items: center; 
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('@/assets/trees.png');
  margin-top: 23px;
  padding-bottom: 550px;
}

.other
{
  display: flex;
  align-items: center;
  gap: 30px;
}

.other-card-wrapper
{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 628px;
}

.other-card-title
{
  color: #497D27;
  text-align: center;
  font-family: Nunito;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
}

.other-card-notice
{
  color: #707070;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.other-card-contacts
{
  display: flex;
  flex-direction: column;
}

.other-card-contact
{
  color: $primary-color;
  text-align: center;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  margin-bottom: 8px;
}

.other-card-messengers
{
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.viber34x36
{
  width: 34px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: url('@/assets/viber34x36.png');
}

.telegram36x36
{
  width: 36px;
  height: 37px;
  background-repeat: no-repeat;
  background-image: url('@/assets/telegram36x36.png');
}

.whatsapp40x40
{
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url('@/assets/whatsapp40x40.png');
}

.other-image
{
  width: 628px;
  height: 543px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/other-image.png');
  position: relative;
  z-index: -1;
}

@media screen and (max-width: 1400px)
{
  .other-image
  {
    width: 498px;
    height: 410px;
  }
}

@media screen and (max-width: 1024px)
{

  .other-wrapper
  {
    background-image: url('@/assets/trees-tablet.png');
    padding-bottom: 540px;
  }

  .other-card-title
  {
    font-size: 26px;
  }

  .other-card-wrapper
  {
    max-width: 330px;
  }
}

@media screen and (max-width: 860px) 
{
  .other-image
  {
    display: none;
  }

  .other-wrapper
  {
    margin-top: 72px;
    padding-bottom: 480px;
  }

  .other-card-title
  {
    font-size: 18px;
  }

  .other-card-contact
  {
    font-size: 22px;
  }

  .other-card-notice
  {
    font-size: 14px;
  }
}
</style>
<template>
  <div id="about-company" class="about-company-wrapper">
    <h2 class="about-company-title">ПОЧЕМУ НАМ ДОВЕРЯЮТ ?</h2>
    <h2 class="about-company-title adaptive">ПОЧЕМУ НАМ <br> ДОВЕРЯЮТ ?</h2>
    <div class="about-company-info"></div>
  </div>
</template>

<style lang="scss">
.about-company-wrapper
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  background: #2F4625;;
  padding-bottom: 102px;
  margin-top: -120px;
}

.about-company-title
{
  position: relative;
  bottom: 40px;
  color: #FFF;
  font-family: Nunito;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &.adaptive {
    display: none;
  }
}

.about-company-info
{
  width: 1423px;
  height: 337px;
  background-image: url('@/assets/about-company-info.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1500px) 
{
  .about-company-info {
    width: 1024px;
    height: 243px;
  }
}

@media screen and (max-width: 1024px) 
{
  .about-company-wrapper
  {
    margin-top: -220px;
  }  

  .about-company-title
  {
    bottom: 25px;
    font-size: 40px;
  }

  .about-company-info
  {
    width: 100%;
    height: 396px;
    background-image: url('@/assets/about-company-info-tablet.png');
  }
}

@media screen and (max-width: 860px)
{
  .about-company-wrapper
  {
    margin-top: -100px;
  } 
}

@media screen and (max-width: 560px)
{
  .about-company-title
  {
    font-size: 30px;
    text-align: center;
    display: none;

    &.adaptive {
      display: unset;
    }
  }
  .about-company-info
  {
    width: 100%;
    height: 623px;
    background-image: url('@/assets/about-company-info-phone.png');
  }
}
</style>
import { Component } from "vue";
import store from "@/store";

type PopupDef = {
	type: string | Component,
	props: any,
	dismissable: boolean,
}

class PopupSystem {
	#host?: Component;
	#popupDefs: Map<string, PopupDef>;
	#activePopupData: PopupDef | null;

	constructor() {
		this.#popupDefs = new Map();
		this.#activePopupData = null;
	}

	init(popupHost: Component) {
		this.#host = popupHost;
	}

	registerPopupType(id: string, popupComponentType: string | Component) {
		this.#popupDefs.set(id, {
			type: popupComponentType,
			props: {},
			dismissable: true,
		});
	}

	invokePopup(id: string, props: any, dismissable: boolean = true): boolean {
		if (!this.#host) return false;
		
		const popupDef = this.#popupDefs.get(id);
		if (popupDef) {
			// Invoke popup using vuex and bind properties
			popupDef.props = props;
			popupDef.dismissable = dismissable;
			store.commit('showPopup', popupDef);

			this.#activePopupData = popupDef;

			return true;
		} else {
			console.warn('No popup with id [' + id + '] registered in system');
			return false;
		}
	}

	dismissPopup(force: boolean = false) {
		if (this.#activePopupData && (this.#activePopupData.dismissable || force)) {
			store.commit('dismissPopup');
			this.#activePopupData = null;
		}
	}
}

export default new PopupSystem();
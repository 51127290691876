import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/fire.svg'


const _hoisted_1 = { class: "product-description" }
const _hoisted_2 = { class: "product-card-name" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 0,
  class: "product-card-desription"
}
const _hoisted_5 = {
  key: 1,
  class: "product-card-configurations"
}
const _hoisted_6 = { class: "product-card-configuration-value" }
const _hoisted_7 = { class: "product-card-configuration-value" }
const _hoisted_8 = { class: "product-prices-wrapper" }
const _hoisted_9 = {
  key: 0,
  class: "price-by-volume"
}
const _hoisted_10 = { class: "product-price" }
const _hoisted_11 = { class: "prices" }
const _hoisted_12 = {
  key: 0,
  class: "product-old-price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['product-card-wrapper', { 'other-products-style' : _ctx.otherProductsStyle }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['product-card-image', _ctx.product.image])
    }, null, 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.product.name) + " ", 1),
        (_ctx.product.showFireIcon)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      (_ctx.product.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.product.description), 1))
        : _createCommentVNode("", true),
      (_ctx.product.configurations || _ctx.product.otherConfigurations)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.product.configurations)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.product.configurations, (value, parameter, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "product-card-configuration",
                    key: index
                  }, [
                    _createElementVNode("span", null, _toDisplayString(parameter) + ":", 1),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(value), 1)
                  ]))
                }), 128))
              : _createCommentVNode("", true),
            (_ctx.product.otherConfigurations)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.product.otherConfigurations, (value, parameter, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "product-card-configuration",
                    key: index
                  }, [
                    _createElementVNode("span", null, _toDisplayString(parameter) + ":", 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(value), 1)
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.product.pricesByVolume)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.pricesByVolume, (price, _key) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "price-by-volume__text",
                key: _key
              }, _toDisplayString(price), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.product.oldPrice)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.product.oldPrice), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(['product-current-price', {'info' : _ctx.currentPriceToInfo}])
          }, _toDisplayString(_ctx.product.currentPrice), 3)
        ]),
        _createElementVNode("div", {
          class: "product-order-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.offer && _ctx.offer(...args)))
        }, "Заказать")
      ])
    ])
  ], 2))
}
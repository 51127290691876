<template>
  <div id="delivery" class="about-purchase-wrapper">
    <h2 class="about-purchase-title">ДОСТАВКА И ОПЛАТА ТОВАРА</h2>
    <div class="about-purchase">
      <div class="delivery-wrapper">
        <div class="delivery-info"></div>
        <div class="delivery-firewood"></div>
      </div>
      <div class="payment-wrapper">
        <div class="payment-fireplace"></div>
        <div class="payment-info"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.about-purchase-wrapper
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  margin-top: 120px;
}

.about-purchase-title
{
  color: $primary-color;
  font-family: Nunito;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about-purchase
{
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.delivery-wrapper
{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.delivery-info
{
  width: 630px;
  height: 499px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/about-delivery.png');
}

.delivery-firewood
{
  width: 630px;
  height: 499px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('@/assets/delivery-firewood.png');
}

.payment-wrapper
{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.payment-info
{
  width: 630px;
  height: 499px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('@/assets/about-payment.png');
}

.payment-fireplace
{
  width: 630px;
  height: 499px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('@/assets/payment-fireplace.png');
}

@media screen and (max-width: 1460px) 
{
  .delivery-info 
  {
    width: 330px;
    height: 410px;
    background-image: url('@/assets/delivery-info-tablet.png');
  }

  .delivery-firewood
  {
    width: 497px;
    height: 410px;
  }

  .payment-fireplace
  {
    width: 497px;
    height: 410px;
  }
  
  .payment-info
  {
    width: 330px;
    height: 356px;
    background-image: url('@/assets/payment-info-tablet.png');
  }
}

@media screen and (max-width: 1024px)
{
  .about-purchase-title
  {
    font-size: 40px;
    font-weight: 700;
  }
}


@media screen and (max-width: 860px) 
{
  .about-purchase-title
  {
    font-size: 28px;
    text-align: center;
  }

  .delivery-wrapper
  {
    flex-direction: column;
  }

  .delivery-info 
  {
    width: 100%;
    height: 238px;
    background-image: url('@/assets/delivery-info-phone.png');
  }

  .delivery-firewood
  {
    width: 360px;
    height: 290px;
    background-image: url('@/assets/delivery-firewood-phone.png');
  }

  .payment-wrapper
  {
    flex-direction: column-reverse;
  }

  .payment-info
  {
    width: 100%;
    height: 196px;
    background-image: url('@/assets/payment-info-phone.png');
  }

  .payment-fireplace
  {
    width: 360px;
    height: 290px;
    background-image: url('@/assets/payment-fireplace-phone.png');
  }
}
</style>
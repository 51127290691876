
import PopupSystem from '@/shared/scripts/PopupSystem';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Main info',
  methods: {
    offer() {
      PopupSystem.invokePopup('offerPopup', {});
    }
  }
})


import { defineComponent } from 'vue';
import Burger from './Burger.vue';

export default defineComponent({
  name: 'Top Bar',
  components: {
    Burger,
  }
})

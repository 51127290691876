import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "popups-host",
    onMousewheel: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onPopupHostClick && _ctx.onPopupHostClick(...args)))
  }, [
    _createElementVNode("div", {
      class: "popup-frame",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activePopupType), {
        modelValue: _ctx.activePopupProps,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activePopupProps) = $event)),
        onClose: _ctx.onClose
      }, null, 40, ["modelValue", "onClose"]))
    ])
  ], 544)), [
    [_vShow, _ctx.activePopupType]
  ])
}
<template>
  <div class="contacts-wrapper" id="contacts">
    <div class="fireplace"></div>
    <div class="contacts">
      <div class="contacts-align-center-wrapper">
        <div class="contact-title-wrapper">
          <router-link to="/" class="logo"></router-link>
          <h1 class="contact-title">дрова по минску и минской области</h1>
          <h1 class="contact-title adaptive">дрова по минску <br> и минской области</h1>
        </div>
        <div class="contact-info-links-wrapper">
          <div class="contacts-info">
            <div class="navigation-buttons-wrapper">
              <div class="wrapper-for-click">
                <router-link to="#catalog" class="navigation-button">Каталог</router-link>
              </div>
              <div class="wrapper-for-click">
                <router-link to="#delivery" class="navigation-button">Доставка</router-link>
              </div>
              <div class="wrapper-for-click">
                <router-link to="#about-company" class="navigation-button">О компании</router-link>
              </div>
              <div class="wrapper-for-click">
                <router-link to="#contacts" class="navigation-button">Контакты</router-link>
              </div>
            </div>
            <div class="contact-info-wrapper">
              <a href="tel:+375 29 551 1881" class="contact-info-number">+375 29 551 1881</a>
              <a href="tel:+375 29 557 5558" class="contact-info-number">+375 29 557 5558</a>
              <div class="time-of-work">09:00 - 21:00</div>
              <div class="contact-info-messengers">
                <a href="viber://chat/?number=%2B375295511881" class="viber34x36black"></a>
                <a href="https://t.me/+375295511881" class="telegram36x36black"></a>
                <a href="https://wa.me/+375295511881" class="whatsapp40x40"></a>
              </div>
            </div>
          </div>
          <div class="contact-links">
            <div class="contact-links-instagram">
              <a href="https://instagram.com/drova_bel_?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" class="instagram"></a>
              <a href="https://instagram.com/drova_bel_?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" class="link-instagram-label">drova_bel_</a>
            </div>
            <span class="link-break-line"></span>
            <div class="contact-links-tik-tok">
              <a href="https://www.tiktok.com/@drovaminsk?_t=8dxYSh4DJ9K" target="_blank" class="tik-tok25x29black"></a>
              <a href="https://www.tiktok.com/@drovabel.by?_t=8oU9Kz7BbIU&_r=1" target="_blank" class="link-tik-tok-label">drovabel.by</a>
            </div>
          </div>
        </div>
        <div class="contacts-notice">Сайт не является интернет-магазином.<br>Цены имеют справочный характер и могут отличаться.</div>
        <div class="contacts-notice adaptive">Сайт не является интернет-магазином. Цены имеют справочный характер и могут отличаться.</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.contacts-wrapper
{
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  background: $primary-color;
}

.fireplace
{
  width: 927px;
  height: 609px;
  background-repeat: no-repeat;
  background-image: url('@/assets/fireplace.png');
}

.contacts
{
  display: flex;
  flex-direction: column;
  margin-top: 51px;
  margin-left: 48px;
}

.contact-title-wrapper
{
  display: flex;
  align-items: center;
  gap: 28px;
  margin-bottom: 73px;

  & .logo {
    flex-shrink: 0;
  }
}

.contact-title
{
  color: #FFF;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  &.adaptive {
    display: none;
  }
}

.contact-info-links-wrapper
{
  display: flex;
  flex-direction: column;
}

.contacts-info
{
  display: flex;
  flex-direction: row;
  gap: 48px;
  margin-bottom: 37px;
}

.navigation-buttons-wrapper
{
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.navigation-button
{
  color: #FFF;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #FFF;
  }
}

.contact-info-wrapper
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.contact-info-number
{
  color: #FFF;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.time-of-work
{
  margin-top: 7px;
  margin-bottom: 20px;
  color: #FFF;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-info-messengers
{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.viber34x36black
{
  width: 34px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: url('@/assets/viber34x36black.png');
}

.telegram36x36black
{
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: url('@/assets/telegram36x36black.png');
}

.whatsapp40x40
{
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url('@/assets/whatsapp40x40.png');
}

.contact-links
{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 53px;
}

.contacts-notice
{
  color: #707070;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  &.adaptive {
    display: none;
  }
}

.contact-links-instagram
{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.link-instagram-label
{
  color: #FFF;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.contact-links-tik-tok
{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.link-break-line
{
  width: 1px;
  height: 28px;
  background: #FFF;
}

.tik-tok25x29black
{
  width: 26px;
  height: 29px;
  background-repeat: no-repeat; 
  background-image: url('@/assets/tik-tok25x29black.png'); 
}

.link-tik-tok-label
{
  color: #FFF;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

@media screen and (max-width: 1400px) 
{
  .fireplace 
  {
    display: none;
  }

  .contact-title-wrapper 
  {
    gap: 45px;
  }

  .contacts 
  {
    margin-left: 70px;
    margin-bottom: 20px;
  }

  .contacts-info 
  {
    gap: 75px;
  }

  .contact-info-messengers 
  {
    gap: 25px;
  }

  .contact-links
  {
    gap: 25px;
  }

  .contact-links-instagram
  {
    gap: 10px;
  }

  .contact-links-tik-tok
  {
    gap: 10px;
  }

  .contacts-notice 
  {
    display: none;

    &.adaptive {
      display: unset;
    }
  }
}


@media screen and (max-width: 1024px)
{
  .contact-info-links-wrapper
  {
    flex-direction: row;
    gap: 75px;
  }

  .contacts
  {
    width: 100%;
    align-items: center;
    margin: 100px 0 100px 0;
  }

  .contact-links
  {
    flex-direction: column;
    justify-content: center;
  }

  .link-break-line
  {
    display: none;
  }

  .contacts-notice 
  {
    display: unset;

    &.adaptive {
      display: none;
    }
  }
}

@media screen and (max-width: 660px)
{
  .contacts
  {
    margin: 41px 25px 56px 25px;
  }

  .contact-title
  {
    display: none;

    &.adaptive {
      display: unset;
    }
  }

  .contact-title-wrapper
  {
    margin-bottom: 36px;
    gap: 20px;

    & .logo {
      width: 77px;
      height: 77px;
    }
  }

  .contact-info-links-wrapper
  {
    flex-direction: column;
    gap: 0;
  }

  .contacts-info
  {
    flex-direction: column;
    gap: 30px;
  }

  .contact-links
  {
    align-items: flex-start;
  }

  .contact-links
  {
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
  }

  .contact-links-instagram
  {
    gap: 15px;
  }

  .link-instagram-label
  {
    font-size: 18px;
  }

  .contact-links-tik-tok
  {
    gap: 15px;
  }

  .link-tik-tok-label
  {
    font-size: 18px;
  }

  .link-break-line
  {
    display: unset;
  }

  .contacts-notice 
  {
    display: none;

    &.adaptive {
      display: unset;
      font-size: 14px;
      color: #D1D1D1;
    }
  }
}

@media screen and (max-width: 440px)
{
  .contact-title
  {
    font-size: 16px;
  }
}
</style>
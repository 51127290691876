import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
		path: '/:pathMatch(.*)*',
    redirect: '/'
	},
];

const router = createRouter({
	routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			if (to.hash === '#about-company') return { el: to.hash, top: 410 };
			return { el: to.hash, top: 100 };
		} else {
			return { top: 0 };
		}
	},
});

export default router;

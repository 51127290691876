import { markRaw } from 'vue';
import { createStore } from 'vuex';

type vuexData = typeof vuexInitData;

function plugVuexState(vuexData: vuexData, state: any, mutations: any, getters: any) {
  Object.assign(vuexData.state, state);
  Object.assign(vuexData.mutations, mutations);
  Object.assign(vuexData.getters, getters);
}

function plugPopupSystemState(vuexData: vuexData) {
  const state = {
    activePopupType: null,
    activePopupProps: null,
  };

  const mutations = {
		showPopup(state: any, popupData: any) {
			if (typeof(popupData.type) == 'object') {
				// markRaw is required if popup type is async component
				state.activePopupType = markRaw(popupData.type);
			} else {
				state.activePopupType = popupData.type;
			}
			
			state.activePopupProps = popupData.props;
		},

    dismissPopup(state: any) {
			state.activePopupType = null;
			state.activePopupProps = null;
		},
  };

  const getters = {};

	plugVuexState(vuexData, state, mutations, getters);
}

const vuexInitData = {
  state: {},
  mutations: {},
  getters: {}
};

plugPopupSystemState(vuexInitData);

const store = createStore(vuexInitData);

export default store;


import { defineComponent } from 'vue';
import Head from '@/components/Head.vue';
import Catalog from '@/components/Catalog.vue';
import AboutPurchase from '@/components/AboutPurchase.vue';
import Other from '@/components/Other.vue';
import AboutCompany from '@/components/AboutCompany.vue';
import FirewoodTypes from '@/components/FirewoodTypes.vue';
import Contacts from '@/components/Contacts.vue';
import PopupsHost from '@/shared/components/popups/PopupsHost.vue';

export default defineComponent({
  name: 'Home View',
  components: {
    Head, 
    Catalog,
    AboutPurchase,
    Other,
    AboutCompany,
    FirewoodTypes,
    Contacts,
    PopupsHost,
  }
})
